import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { CSSProperties, memo, useMemo } from "react";

type Props = {
  incomingStyle?: CSSProperties;
  onClick: () => void;
};

const defaultStyle: CSSProperties = {
  position: "absolute",
  right: "12px",
  top: "16px",
  marginTop: "-4px",
};

function CloseButton({ incomingStyle, onClick }: Props) {
  const style: CSSProperties = useMemo(
    () => ({ ...defaultStyle, ...incomingStyle }),
    [incomingStyle]
  );

  return (
    <IconButton style={style} onClick={onClick}>
      <Close />
    </IconButton>
  );
}

export default memo(CloseButton);
