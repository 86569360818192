import { Box, Typography } from "@mui/material";
import { memo } from "react";
import classes from "./classes.module.css";
import MyPicture from "./MyPicture";
import { useLang } from "../../../common/contexts/lang/context";

function MyProfile() {
  const { dictionary } = useLang();

  return (
    <Box className={classes.root}>
      <Box margin="auto">
        <MyPicture />
      </Box>

      <Box className={classes.me}>
        <Typography variant="h1" fontSize={24} fontWeight={500}>
          {dictionary.home.myProfile.name}
        </Typography>

        <Typography variant="h1" fontSize={20} fontWeight={400}>
          {dictionary.home.myProfile.job}
        </Typography>

        <Typography variant="h1" fontSize={12}>
          {dictionary.home.myProfile.speciality}
        </Typography>
      </Box>

      <Box className={classes.description}>
        {dictionary.home.myProfile.descriptions.map((description, index) => {
          return (
            <Typography
              variant="body1"
              mt={index === 0 ? 0 : 1}
              key={description}
            >
              {description}
            </Typography>
          );
        })}

        {dictionary.home.myProfile.histories.map((history) => {
          return (
            <Box key={history.title}>
              <Typography variant="h2" fontSize={20} fontWeight={500} mt={3}>
                {history.title}
              </Typography>
              {history.descriptions.map((description) => {
                return (
                  <Typography variant="body1" mt={1} key={description}>
                    {description}
                  </Typography>
                );
              })}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default memo(MyProfile);
