import { Box, Typography, useTheme } from "@mui/material";
import { memo } from "react";
import classes from "./classes.module.css";
import { useLang } from "../../common/contexts/lang/context";
import CustomLink from "../CustomLink";

function Footer() {
  const { dictionary } = useLang();
  const theme = useTheme();
  return (
    <Box className={classes.root}>
      <Box className={classes.link}>
        <Typography variant="body2" fontSize={12} fontStyle="italic">
          {dictionary.footer.author}
        </Typography>
        <CustomLink
          to="https://www.linkedin.com/in/rael-calitro-4a519a187/"
          target="_blank"
        >
          <Typography variant="caption" color={theme.palette.primary.dark}>
            LinkedIn
          </Typography>
        </CustomLink>
        -{" "}
        <CustomLink to="https://github.com/rael06" target="_blank">
          <Typography variant="caption" color={theme.palette.primary.dark}>
            Github
          </Typography>
        </CustomLink>
      </Box>
    </Box>
  );
}

export default memo(Footer);
