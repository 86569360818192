import { PropsWithChildren, memo } from "react";
import { Link, LinkProps } from "react-router-dom";
import classes from "./classes.module.css";

function CustomLink({ children, ...link }: PropsWithChildren<LinkProps>) {
  return (
    <Link to={link.to} className={classes.root} target={link.target}>
      {children}
    </Link>
  );
}

export default memo(CustomLink);
