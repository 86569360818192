import { memo } from "react";
import { Helmet } from "react-helmet";
import { useLang } from "../../common/contexts/lang/context";
import { Box } from "@mui/material";
import MyProfile from "./MyProfile";
import classes from "./classes.module.css";

function HomePage() {
  const { dictionary } = useLang();

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{dictionary.home.title} - Rael CALITRO</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href="https://rael-calitro.ovh" />
      </Helmet>

      <Box className={classes.root}>
        <Box className={classes.myProfile}>
          <MyProfile />
        </Box>
      </Box>
    </div>
  );
}

export default memo(HomePage);
