import { Box } from "@mui/material";
import { memo } from "react";
import classes from "./classes.module.css";

function MyPicture() {
  return (
    <Box className={classes.root}>
      <img
        className={classes.image}
        src="/rael-calitro.jpg"
        alt="Rael Calitro"
      />
    </Box>
  );
}

export default memo(MyPicture);
