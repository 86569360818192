import { Tabs } from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import NavTab, { NavTabProps } from "./NavTab";

type Props = {
  tabs: NavTabProps[];
  height?: number;
};

function NavTabs({ tabs, height }: Props) {
  const [value, setValue] = useState<number | boolean>(false);

  useEffect(() => {
    const index = tabs.findIndex((tab) => tab.selected);

    setValue(index !== -1 ? index : false);
  }, [tabs]);

  const handleChange = useCallback(
    (_: React.SyntheticEvent, newIndex: number | boolean) => {
      setValue(newIndex !== -1 ? newIndex : false);
    },
    [setValue]
  );

  const sx = useMemo(() => (height ? { height } : {}), [height]);

  return (
    <Tabs value={value} onChange={handleChange} role="navigation" sx={sx}>
      {tabs.map((tab) => (
        <NavTab
          key={tab.href}
          label={tab.label}
          href={tab.href}
          icon={tab.icon}
          iconPosition={tab.iconPosition}
        />
      ))}
    </Tabs>
  );
}

export default memo(NavTabs);
