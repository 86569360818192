import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { useLang } from "../../common/contexts/lang/context";

function Page404() {
  const { dictionary } = useLang();

  return (
    <Box display="flex" gap={2} flexDirection="column">
      <Typography variant="body1">{dictionary.page404.description}</Typography>
    </Box>
  );
}

export default memo(Page404);
