import { Tab, TabOwnProps } from "@mui/material";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

export type NavTabProps = TabOwnProps & {
  href: string;
  selected?: boolean;
};

function NavTab(props: NavTabProps) {
  const navigate = useNavigate();
  const goto = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      navigate(props.href);
    },
    [navigate, props.href]
  );

  return (
    <Tab
      component="a"
      onClick={goto}
      aria-current={props.selected && "page"}
      {...props}
    />
  );
}

export default memo(NavTab);
