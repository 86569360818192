import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { Helmet } from "react-helmet";
import { useLang } from "../../common/contexts/lang/context";
import SectionCard from "../SectionCard";
import classes from "./classes.module.css";
import { Dictionary } from "../../translations/Dictionary";

type Props = {
  pageType: keyof Pick<Dictionary, "articles" | "projects">;
};

function SectionsPage({ pageType }: Props) {
  const { dictionary } = useLang();

  return (
    <Box className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{dictionary[pageType].title} - Rael CALITRO</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href="https://rael-calitro.ovh" />
      </Helmet>

      <Box className={classes.descriptions}>
        {dictionary[pageType].descriptions.map((description) => (
          <Typography key={description}>{description}</Typography>
        ))}
      </Box>

      <Box className={classes.sections}>
        {dictionary[pageType].sections.map((section) => (
          <SectionCard key={section.title} {...section} />
        ))}
      </Box>
    </Box>
  );
}

export default memo(SectionsPage);
