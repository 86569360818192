import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
  useTheme,
} from "@mui/material";
import { memo, useCallback } from "react";
import classes from "./classes.module.css";
import { Dictionary } from "../../translations/Dictionary";
import CustomLink from "../CustomLink";

type Props = Dictionary["projects" | "articles"]["sections"][number];

function SectionCard(props: Props) {
  const theme = useTheme();

  const handleCardClick = useCallback(() => {
    window.open(props.href, "_blank");
  }, [props.href]);

  return (
    <Card className={classes.card} component="section">
      <CardHeader
        title={
          <Box className={classes.titleContainer}>
            <Typography
              variant="h2"
              color={theme.palette.primary.dark}
              fontSize={22}
              fontWeight={400}
              className={classes.title}
              onClick={handleCardClick}
            >
              {props.title}
            </Typography>
          </Box>
        }
      />

      <CardContent sx={{ paddingTop: 0 }}>
        <Box className={classes.mainContent}>
          <Box className={classes.description}>
            {props.descriptions.map((description, index) => (
              <Typography
                key={description}
                variant="body1"
                mt={index === 0 ? 0 : 1}
              >
                {description}
              </Typography>
            ))}
          </Box>

          {props.image && (
            <Box className={classes.media}>
              <CardMedia
                component="img"
                image={props.image.src}
                alt={props.image.alt}
              />
            </Box>
          )}
        </Box>
        <Box className={classes.footer}>
          <Box className={classes.dateAndLinks}>
            <Typography variant="caption" color="textSecondary">
              {props.date},
            </Typography>
            {props.links && (
              <Box className={classes.links}>
                {props.links.map((link) => (
                  <CustomLink key={link.href} to={link.href} target="_blank">
                    <Typography
                      variant="caption"
                      color={theme.palette.primary.dark}
                    >
                      {link.label}
                    </Typography>
                  </CustomLink>
                ))}
              </Box>
            )}
          </Box>

          <Box className={classes.tags}>
            {props.tags.map((tag) => (
              <Typography
                key={tag}
                variant="caption"
                color="textSecondary"
                component="span"
              >
                #{tag}
              </Typography>
            ))}
          </Box>
          {props.credits && (
            <Typography variant="caption" color="textSecondary">
              {props.credits}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default memo(SectionCard);
