import { IconButton } from "@mui/material";
import { memo } from "react";
import { ContentCopy } from "@mui/icons-material";

type Props = {
  value: string;
};

function CopyToClipboard({ value }: Props) {
  function copyToClipboard() {
    navigator.clipboard.writeText(value);
  }

  return (
    <IconButton onClick={copyToClipboard} size="small">
      <ContentCopy />
    </IconButton>
  );
}

export default memo(CopyToClipboard);
