import { Typography } from "@mui/material";
import Router from "./Router";
import { ErrorBoundary } from "react-error-boundary";

function App() {
  return (
    <ErrorBoundary
      fallback={<Typography>Sorry, an error has occurred.</Typography>}
    >
      <Router />
    </ErrorBoundary>
  );
}

export default App;
