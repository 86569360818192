import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import { memo, useCallback } from "react";
import classes from "./classes.module.css";
import { Lang, useLang } from "../../common/contexts/lang/context";
import { z } from "zod";
import NavTabs from "../NavTabs";
import { useMatch } from "react-router-dom";
import { QrCode } from "@mui/icons-material";
import useOpenable from "../../common/hooks/useOpenable";
import ContactDialog from "../dialogs/ContactDialog";

const selectStyle = { width: 60, fontSize: 12 };

function Header() {
  const { lang, updateLang, dictionary } = useLang();
  const { isOpen, open, close } = useOpenable();
  const theme = useTheme();

  const handleLangChange = useCallback(
    (e: SelectChangeEvent<Lang>) => {
      const langValidation = z.nativeEnum(Lang).safeParse(e.target.value);
      if (!langValidation.success) return;

      updateLang(langValidation.data);
    },
    [updateLang]
  );

  const handleQrClick = useCallback(() => {
    open();
  }, [open]);

  const isHomePage = !!useMatch("/");
  const isProjectsPage = !!useMatch("/my-projects");
  const isArticlesPage = !!useMatch("/my-articles");

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.content}>
          <Box>
            <Box className={classes.description}>
              <Typography
                variant="h1"
                fontSize={20}
                fontWeight={500}
                color={theme.palette.primary.dark}
              >
                {dictionary.header.title}
              </Typography>

              <Typography variant="body2" fontSize={11}>
                {dictionary.header.description}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.rightPart}>
            <Button onClick={handleQrClick}>
              <Typography fontSize={12} mr="4px">
                {dictionary.dialogs.contact.title}
              </Typography>
              <QrCode />
            </Button>

            <Box className={classes.lang}>
              <Select
                value={lang}
                onChange={handleLangChange}
                size="small"
                sx={selectStyle}
              >
                <MenuItem value={Lang.En}>{Lang.En}</MenuItem>
                <MenuItem value={Lang.Fr}>{Lang.Fr}</MenuItem>
              </Select>
            </Box>
          </Box>
        </Box>

        <NavTabs
          tabs={[
            {
              label: dictionary.home.title,
              href: "/",
              selected: isHomePage,
            },
            {
              label: dictionary.projects.title,
              href: "/my-projects",
              selected: isProjectsPage,
            },
            {
              label: dictionary.articles.title,
              href: "/my-articles",
              selected: isArticlesPage,
            },
          ]}
        />
      </Box>

      <ContactDialog isOpen={isOpen} close={close} />
    </>
  );
}

export default memo(Header);
